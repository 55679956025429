/**
 * Library Library
 **/
export const SET_LIBRARY = 'SET_LIBRARY';
export const GET_LIBRARY = 'GET_LIBRARY';

/**
 * Library Announcements
 **/
export const SET_LIBRARY_ANNOUNCEMENTS = 'SET_LIBRARY_ANNOUNCEMENTS';
export const SET_ACTIVE_LIBRARY_ANNOUNCEMENT =
  'SET_ACTIVE_LIBRARY_ANNOUNCEMENT';
export const GET_LIBRARY_ANNOUNCEMENTS = 'GET_LIBRARY_ANNOUNCEMENTS';
export const GET_LIBRARY_ANNOUNCEMENTS_SUCCESS =
  'GET_LIBRARY_ANNOUNCEMENTS_SUCCESS';
export const GET_LIBRARY_ANNOUNCEMENTS_FAILED =
  'GET_LIBRARY_ANNOUNCEMENTS_FAILED';

export const CREATE_LIBRARY_ANNOUNCEMENT = 'CREATE_LIBRARY_ANNOUNCEMENT';
export const CREATE_LIBRARY_ANNOUNCEMENT_SUCCESS =
  'CREATE_LIBRARY_ANNOUNCEMENT_SUCCESS';
export const CREATE_LIBRARY_ANNOUNCEMENT_FAILED =
  'CREATE_LIBRARY_ANNOUNCEMENT_FAILED';

export const UPDATE_LIBRARY_ANNOUNCEMENT = 'UPDATE_LIBRARY_ANNOUNCEMENT';
export const UPDATE_LIBRARY_ANNOUNCEMENT_SUCCESS =
  'UPDATE_LIBRARY_ANNOUNCEMENT_SUCCESS';
export const UPDATE_LIBRARY_ANNOUNCEMENT_FAILED =
  'UPDATE_LIBRARY_ANNOUNCEMENT_FAILED';

export const DELETE_LIBRARY_ANNOUNCEMENT = 'DELETE_LIBRARY_ANNOUNCEMENT';
export const DELETE_LIBRARY_ANNOUNCEMENT_SUCCESS =
  'DELETE_LIBRARY_ANNOUNCEMENT_SUCCESS';
export const DELETE_LIBRARY_ANNOUNCEMENT_FAILED =
  'DELETE_LIBRARY_ANNOUNCEMENT_FAILED';

/**
 * Library Computers List
 **/
export const SET_LIBRARY_COMPUTERS = 'SET_LIBRARY_COMPUTERS';
export const GET_LIBRARY_COMPUTERS = 'GET_LIBRARY_COMPUTERS';
export const GET_LIBRARY_COMPUTERS_SUCCESS = 'GET_LIBRARY_COMPUTERS_SUCCESS';
export const GET_LIBRARY_COMPUTERS_FAILED = 'GET_LIBRARY_COMPUTERS_FAILED';

/**
 * Library Students
 **/
export const SET_LIBRARY_STUDENTS = 'SET_LIBRARY_STUDENTS';
export const SET_ACTIVE_LIBRARY_STUDENT = 'SET_ACTIVE_LIBRARY_STUDENT';

export const GET_LIBRARY_STUDENTS = 'GET_LIBRARY_STUDENTS';
export const GET_LIBRARY_STUDENTS_SUCCESS = 'GET_LIBRARY_STUDENTS_SUCCESS';
export const GET_LIBRARY_STUDENTS_FAILED = 'GET_LIBRARY_STUDENTS_FAILED';

export const UPDATE_LIBRARY_STUDENT = 'UPDATE_LIBRARY_STUDENT';
export const UPDATE_LIBRARY_STUDENT_SUCCESS = 'UPDATE_LIBRARY_STUDENT_SUCCESS';
export const UPDATE_LIBRARY_STUDENT_FAILED = 'UPDATE_LIBRARY_STUDENT_FAILED';

export const DELETE_LIBRARY_STUDENT = 'DELETE_LIBRARY_STUDENT';
export const DELETE_LIBRARY_STUDENT_SUCCESS = 'DELETE_LIBRARY_STUDENT_SUCCESS';
export const DELETE_LIBRARY_STUDENT_FAILED = 'DELETE_LIBRARY_STUDENT_FAILED';

export const UPDATE_LIBRARY_STUDENT_STATUS = 'UPDATE_LIBRARY_STUDENT_STATUS';
export const UPDATE_LIBRARY_STUDENT_STATUS_SUCCESS =
  'UPDATE_LIBRARY_STUDENT_STATUS_SUCCESS';
export const UPDATE_LIBRARY_STUDENT_STATUS_FAILED =
  'UPDATE_LIBRARY_STUDENT_STATUS_FAILED';

/**
 * Library Mentors
 **/
export const SET_LIBRARY_MENTORS = 'SET_LIBRARY_MENTORS';
export const GET_LIBRARY_MENTORS = 'GET_LIBRARY_MENTORS';
export const GET_LIBRARY_MENTORS_SUCCESS = 'GET_LIBRARY_MENTORS_SUCCESS';
export const GET_LIBRARY_MENTORS_FAILED = 'GET_LIBRARY_MENTORS_FAILED';

export const UPDATE_LIBRARY_MENTOR = 'UPDATE_LIBRARY_MENTOR';
export const UPDATE_LIBRARY_MENTOR_SUCCESS = 'UPDATE_LIBRARY_MENTOR_SUCCESS';
export const UPDATE_LIBRARY_MENTOR_FAILED = 'UPDATE_LIBRARY_MENTOR_FAILED';

export const DELETE_LIBRARY_MENTOR = 'DELETE_LIBRARY_MENTOR';
export const DELETE_LIBRARY_MENTOR_SUCCESS = 'DELETE_LIBRARY_MENTOR_SUCCESS';
export const DELETE_LIBRARY_MENTOR_FAILED = 'DELETE_LIBRARY_MENTOR_FAILED';

export const UPDATE_LIBRARY_MENTOR_STATUS = 'UPDATE_LIBRARY_MENTOR_STATUS';
export const UPDATE_LIBRARY_MENTOR_STATUS_SUCCESS =
  'UPDATE_LIBRARY_MENTOR_STATUS_SUCCESS';
export const UPDATE_LIBRARY_MENTOR_STATUS_FAILED =
  'UPDATE_LIBRARY_MENTOR_STATUS_FAILED';

/**
 * Library Active Computer
 **/
export const SET_ACTIVE_LIBRARY_COMPUTER = 'SET_ACTIVE_LIBRARY_COMPUTER';
export const CREATE_LIBRARY_COMPUTER = 'CREATE_LIBRARY_COMPUTER';
export const CREATE_LIBRARY_COMPUTER_SUCCESS =
  'CREATE_LIBRARY_COMPUTER_SUCCESS';
export const CREATE_LIBRARY_COMPUTER_FAILED = 'CREATE_LIBRARY_COMPUTER_FAILED';

export const UPDATE_LIBRARY_COMPUTER = 'UPDATE_LIBRARY_COMPUTER';
export const UPDATE_LIBRARY_COMPUTER_SUCCESS =
  'UPDATE_LIBRARY_COMPUTER_SUCCESS';
export const UPDATE_LIBRARY_COMPUTER_FAILED = 'UPDATE_LIBRARY_COMPUTER_FAILED';

export const DELETE_LIBRARY_COMPUTER = 'DELETE_LIBRARY_COMPUTER';
export const DELETE_LIBRARY_COMPUTER_SUCCESS =
  'DELETE_LIBRARY_COMPUTER_SUCCESS';
export const DELETE_LIBRARY_COMPUTER_FAILED = 'DELETE_LIBRARY_COMPUTER_FAILED';

/**
 * Library Computer Preference Slots
 **/
export const SET_ACTIVE_LIBRARY_USER_PREFERENCE_SLOTS =
  'SET_ACTIVE_LIBRARY_USER_PREFERENCE_SLOTS';

export const SET_ACTIVE_LIBRARY_USER_PREFERENCE_SLOT =
  'SET_ACTIVE_LIBRARY_USER_PREFERENCE_SLOT';

export const GET_LIBRARY_USER_PREFERENCE_SLOTS =
  'GET_LIBRARY_USER_PREFERENCE_SLOTS';
export const GET_LIBRARY_USER_PREFERENCE_SLOTS_SUCCESS =
  'GET_LIBRARY_USER_PREFERENCE_SLOTS_SUCCESS';
export const GET_LIBRARY_USER_PREFERENCE_SLOTS_FAILED =
  'GET_LIBRARY_USER_PREFERENCE_SLOTS_FAILED';

export const CREATE_LIBRARY_USER_PREFERENCE_SLOT =
  'CREATE_LIBRARY_USER_PREFERENCE_SLOT';
export const CREATE_LIBRARY_USER_PREFERENCE_SLOT_SUCCESS =
  'CREATE_LIBRARY_USER_PREFERENCE_SLOT_SUCCESS';
export const CREATE_LIBRARY_USER_PREFERENCE_SLOT_FAILED =
  'CREATE_LIBRARY_USER_PREFERENCE_SLOT_FAILED';

export const UPDATE_LIBRARY_USER_PREFERENCE_SLOT =
  'UPDATE_LIBRARY_USER_PREFERENCE_SLOT';
export const UPDATE_LIBRARY_USER_PREFERENCE_SLOT_SUCCESS =
  'UPDATE_LIBRARY_USER_PREFERENCE_SLOT_SUCCESS';
export const UPDATE_LIBRARY_USER_PREFERENCE_SLOT_FAILED =
  'UPDATE_LIBRARY_USER_PREFERENCE_SLOT_FAILED';

export const DELETE_LIBRARY_USER_PREFERENCE_SLOT =
  'DELETE_LIBRARY_USER_PREFERENCE_SLOT';
export const DELETE_LIBRARY_USER_PREFERENCE_SLOT_SUCCESS =
  'DELETE_LIBRARY_USER_PREFERENCE_SLOT_SUCCESS';
export const DELETE_LIBRARY_USER_PREFERENCE_SLOT_FAILED =
  'DELETE_LIBRARY_USER_PREFERENCE_SLOT_FAILED';

/**
 * Library Reservation Slots
 **/
export const SET_ACTIVE_LIBRARY_COMPUTER_RESERVATION_SLOTS =
  'SET_ACTIVE_LIBRARY_COMPUTER_RESERVATION_SLOTS';

export const SET_ACTIVE_LIBRARY_COMPUTER_RESERVATION =
  'SET_ACTIVE_LIBRARY_COMPUTER_RESERVATION';

export const GET_LIBRARY_COMPUTER_RESERVATIONS =
  'GET_LIBRARY_COMPUTER_RESERVATIONS';
export const GET_LIBRARY_COMPUTER_RESERVATIONS_SUCCESS =
  'GET_LIBRARY_COMPUTER_RESERVATIONS_SUCCESS';
export const GET_LIBRARY_COMPUTER_RESERVATIONS_FAILED =
  'GET_LIBRARY_COMPUTER_RESERVATIONS_FAILED';

export const CREATE_LIBRARY_COMPUTER_RESERVATION =
  'CREATE_LIBRARY_COMPUTER_RESERVATION';
export const CREATE_LIBRARY_COMPUTER_RESERVATION_SUCCESS =
  'CREATE_LIBRARY_COMPUTER_RESERVATION_SUCCESS';
export const CREATE_LIBRARY_COMPUTER_RESERVATION_FAILED =
  'CREATE_LIBRARY_COMPUTER_RESERVATION_FAILED';

export const UPDATE_LIBRARY_COMPUTER_RESERVATION =
  'UPDATE_LIBRARY_COMPUTER_RESERVATION';
export const UPDATE_LIBRARY_COMPUTER_RESERVATION_SUCCESS =
  'UPDATE_LIBRARY_COMPUTER_RESERVATION_SUCCESS';
export const UPDATE_LIBRARY_COMPUTER_RESERVATION_FAILED =
  'UPDATE_LIBRARY_COMPUTER_RESERVATION_FAILED';

export const DELETE_LIBRARY_COMPUTER_RESERVATION =
  'DELETE_LIBRARY_COMPUTER_RESERVATION';
export const DELETE_LIBRARY_COMPUTER_RESERVATION_SUCCESS =
  'DELETE_LIBRARY_COMPUTER_RESERVATION_SUCCESS';
export const DELETE_LIBRARY_COMPUTER_RESERVATION_FAILED =
  'DELETE_LIBRARY_COMPUTER_RESERVATION_FAILED';

/**
 * Library Time Slots
 **/
export const SET_LIBRARY_TIME_SLOTS = 'SET_LIBRARY_TIME_SLOTS';
export const SET_ACTIVE_LIBRARY_TIME_SLOT = 'SET_ACTIVE_LIBRARY_TIME_SLOT';

export const GET_LIBRARY_TIME_SLOTS = 'GET_LIBRARY_TIME_SLOTS';
export const GET_LIBRARY_TIME_SLOTS_SUCCESS = 'GET_LIBRARY_TIME_SLOTS_SUCCESS';
export const GET_LIBRARY_TIME_SLOTS_FAILED = 'GET_LIBRARY_TIME_SLOTS_FAILED';

export const CREATE_LIBRARY_TIME_SLOT = 'CREATE_LIBRARY_TIME_SLOT';
export const CREATE_LIBRARY_TIME_SLOT_SUCCESS =
  'CREATE_LIBRARY_TIME_SLOT_SUCCESS';
export const CREATE_LIBRARY_TIME_SLOT_FAILED =
  'CREATE_LIBRARY_TIME_SLOT_FAILED';

export const UPDATE_LIBRARY_TIME_SLOT = 'UPDATE_LIBRARY_TIME_SLOT';
export const UPDATE_LIBRARY_TIME_SLOT_SUCCESS =
  'UPDATE_LIBRARY_TIME_SLOT_SUCCESS';
export const UPDATE_LIBRARY_TIME_SLOT_FAILED =
  'UPDATE_LIBRARY_TIME_SLOT_FAILED';

export const DELETE_LIBRARY_TIME_SLOT = 'DELETE_LIBRARY_TIME_SLOT';
export const DELETE_LIBRARY_TIME_SLOT_SUCCESS =
  'DELETE_LIBRARY_TIME_SLOT_SUCCESS';
export const DELETE_LIBRARY_TIME_SLOT_FAILED =
  'DELETE_LIBRARY_TIME_SLOT_FAILED';

export interface Library {
  id?: number;
  announcements: any[];
  reservations: any[];
  user_preference_slots: any[];
  library_slots: any[];
  computers: any[];
  students: any[];
  mentors: any[];
  activeComputer: any | null;
  activeLibrary: any | null;
  activeUserSlot: any | null;
  activeComputerReservationSlot: any | null;
  activeLibrarySlot: any | null;
  isAcceptingNewMentors: boolean;
  name: string;
  uniqueID: string;
}

export interface GetLibraryAction {
  type: typeof GET_LIBRARY;
  uniqueID: string;
}
export interface SetLibraryAction {
  type: typeof SET_LIBRARY;
  payload: any;
}

export interface GetLibraryAnnouncementsAction {
  type: typeof GET_LIBRARY_ANNOUNCEMENTS;
  uniqueID: string;
}
export interface SetLibraryAnnouncementsAction {
  type: typeof SET_LIBRARY_ANNOUNCEMENTS;
  payload: any;
}

export interface GetLibraryComputersAction {
  type: typeof GET_LIBRARY_COMPUTERS;
  uniqueID: string;
}
export interface SetLibraryComputersAction {
  type: typeof SET_LIBRARY_COMPUTERS;
  payload: any;
}

export interface GetLibraryStudentsAction {
  type: typeof GET_LIBRARY_STUDENTS;
  uniqueID: string;
}
export interface SetLibraryStudentsAction {
  type: typeof SET_LIBRARY_STUDENTS;
  payload: any;
}

export interface GetLibraryMentorsAction {
  type: typeof GET_LIBRARY_MENTORS;
  uniqueID: string;
}
export interface SetLibraryMentorsAction {
  type: typeof SET_LIBRARY_MENTORS;
  payload: any;
}

/**
 * Library Computers
 **/

export interface SetActiveLibraryComputerAction {
  type: typeof SET_ACTIVE_LIBRARY_COMPUTER;
  payload: any;
}
export interface CreateLibraryComputerAction {
  type: typeof CREATE_LIBRARY_COMPUTER;
  payload: any;
}

export interface UpdateLibraryComputerAction {
  type: typeof UPDATE_LIBRARY_COMPUTER;
  uniqueID: string;
  payload: any;
}

export interface DeleteLibraryComputerAction {
  type: typeof DELETE_LIBRARY_COMPUTER;
  uniqueID: string;
}

/**
 * Library Announcements
 **/

export interface SetActiveLibraryAnnouncementAction {
  type: typeof SET_ACTIVE_LIBRARY_ANNOUNCEMENT;
  payload: any;
}

export interface CreateLibraryAnnouncementAction {
  type: typeof CREATE_LIBRARY_ANNOUNCEMENT;
  payload: any;
}

export interface UpdateLibraryAnnouncementAction {
  type: typeof UPDATE_LIBRARY_ANNOUNCEMENT;
  payload: any;
}

export interface DeleteLibraryAnnouncementAction {
  type: typeof DELETE_LIBRARY_ANNOUNCEMENT;
  payload: any;
}

/**
 * Library User and Mentor Updates
 **/

export interface DeleteStudentAction {
  type: typeof DELETE_LIBRARY_STUDENT;
  id: number;
}

export interface DeleteMentorAction {
  type: typeof DELETE_LIBRARY_MENTOR;
  id: number;
}

export interface ChangeStudentStatusAction {
  type: typeof UPDATE_LIBRARY_STUDENT_STATUS;
  payload: any;
}

export interface ChangeMentorStatusAction {
  type: typeof UPDATE_LIBRARY_MENTOR_STATUS;
  payload: any;
}

/**
 * Library User Preference Slots
 **/

export interface GetLibraryUserPreferenceSlotsAction {
  type: typeof GET_LIBRARY_USER_PREFERENCE_SLOTS;
  uniqueID: string;
}

export interface SetLibraryUserPreferenceSlotsAction {
  type: typeof SET_ACTIVE_LIBRARY_USER_PREFERENCE_SLOTS;
  payload: any;
}

export interface SetActiveLibraryUserPreferenceSlotAction {
  type: typeof SET_ACTIVE_LIBRARY_USER_PREFERENCE_SLOT;
  payload: any;
}

export interface CreateLibraryUserPreferenceSlotAction {
  type: typeof CREATE_LIBRARY_USER_PREFERENCE_SLOT;
  payload: any;
}

export interface UpdateLibraryUserPreferenceSlotAction {
  type: typeof UPDATE_LIBRARY_USER_PREFERENCE_SLOT;
  uniqueID: string;
  payload: any;
}

export interface DeleteLibraryUserPreferenceSlotAction {
  type: typeof DELETE_LIBRARY_USER_PREFERENCE_SLOT;
  uniqueID: string;
}

/**
 * Library Reservations Slots
 **/

export interface GetLibraryComputerReservationsAction {
  type: typeof GET_LIBRARY_COMPUTER_RESERVATIONS;
  uniqueID: string;
}

export interface SetLibraryComputerReservationsAction {
  type: typeof SET_ACTIVE_LIBRARY_COMPUTER_RESERVATION_SLOTS;
  payload: any;
}

export interface SetActiveLibraryComputerReservationAction {
  type: typeof SET_ACTIVE_LIBRARY_COMPUTER_RESERVATION;
  payload: any;
}

export interface CreateLibraryComputerReservationAction {
  type: typeof CREATE_LIBRARY_COMPUTER_RESERVATION;
  payload: any;
}

export interface UpdateLibraryComputerReservationAction {
  type: typeof UPDATE_LIBRARY_COMPUTER_RESERVATION;
  uniqueID: string;
  payload: any;
}

export interface DeleteLibraryComputerReservationAction {
  type: typeof DELETE_LIBRARY_COMPUTER_RESERVATION;
  uniqueID: any;
}

/**
 * Library Time Slots
 **/

export interface GetLibraryTimeSlotsAction {
  type: typeof GET_LIBRARY_TIME_SLOTS;
  uniqueID: string;
}

export interface SetLibraryTimeSlotsAction {
  type: typeof SET_LIBRARY_TIME_SLOTS;
  payload: any;
}

export interface SetActiveLibraryTimeSlotAction {
  type: typeof SET_ACTIVE_LIBRARY_TIME_SLOT;
  payload: any;
}

export interface CreateLibraryTimeSlotAction {
  type: typeof CREATE_LIBRARY_TIME_SLOT;
  payload: any;
}

export interface UpdateLibraryTimeSlotAction {
  type: typeof UPDATE_LIBRARY_TIME_SLOT;
  uniqueID: string;
  payload: any;
}

export interface DeleteLibraryTimeSlotAction {
  type: typeof DELETE_LIBRARY_TIME_SLOT;
  uniqueID: string;
}

export type LibraryActions =
  | DeleteMentorAction
  | DeleteStudentAction
  | ChangeMentorStatusAction
  | ChangeStudentStatusAction
  | DeleteLibraryAnnouncementAction
  | UpdateLibraryAnnouncementAction
  | SetActiveLibraryAnnouncementAction
  | CreateLibraryAnnouncementAction
  | CreateLibraryComputerAction
  | UpdateLibraryComputerAction
  | DeleteLibraryComputerAction
  | SetActiveLibraryComputerAction
  | GetLibraryMentorsAction
  | SetLibraryMentorsAction
  | GetLibraryStudentsAction
  | SetLibraryStudentsAction
  | GetLibraryAction
  | SetLibraryAction
  | GetLibraryAnnouncementsAction
  | SetLibraryAnnouncementsAction
  | SetLibraryComputersAction
  | GetLibraryComputersAction
  | GetLibraryUserPreferenceSlotsAction
  | SetActiveLibraryUserPreferenceSlotAction
  | SetLibraryUserPreferenceSlotsAction
  | CreateLibraryUserPreferenceSlotAction
  | UpdateLibraryUserPreferenceSlotAction
  | DeleteLibraryUserPreferenceSlotAction
  | GetLibraryComputerReservationsAction
  | SetLibraryComputerReservationsAction
  | SetActiveLibraryComputerReservationAction
  | CreateLibraryComputerReservationAction
  | UpdateLibraryComputerReservationAction
  | DeleteLibraryComputerReservationAction
  | GetLibraryTimeSlotsAction
  | SetLibraryTimeSlotsAction
  | SetActiveLibraryTimeSlotAction
  | CreateLibraryTimeSlotAction
  | UpdateLibraryTimeSlotAction
  | DeleteLibraryTimeSlotAction;
