import { all } from 'redux-saga/effects';
import {
  watchAutoLogin,
  watchGetGenres,
  watchGetCareers,
  watchGetLanguages,
  watchGetSubjects,
  watchGetTimezones,
  watchLogin,
  watchOnboardMentor,
  watchOnboardStudent,
  watchSubmitMentorSignUpForm,
  watchSubmitStudentRegistration,
  watchTaskStep,
  watchGetOpportunity,
  watchGetLibraryComputerSlots,
  watchGetUserPreferencesSlots,
  watchGetAnnouncements,
  watchUpdateAnnouncement,
  watchDeleteAnnouncement,
  watchGetLibraryComputers,
  watchGetLibrary,
  watchDeleteUserPreferencesSlotsSuccess,
  watchGetLibraryStudentPreferenceSlots,
  watchGetUserComputerReservationSlot,
  watchCreateComputerReservationSlot,
  watchCreateAnnouncement,
  watchUpdateUserComputerReservationAttendance,
  watchGetLibraryStudents,
  watchCreateUserPreferenceSlot,
  watchDeleteUserPreferenceSlot,
  watchGetLibraryMentors,
  watchGetUserDetail,
  watchVerifyMentorEmail,
  watchCreateLibraryComputers,
  watchChangeStudentStatus,
  watchChangeMentorStatus,
  watchLibraryUserPreferenceSlots,
  watchLibraryComputerReservations,
  watchLibraryTimeSlots,
  watchUpdateMentorProfile,
  watchLogout,
} from './actions';

export default function* rootSaga() {
  yield all([
    watchLogout(),
    watchVerifyMentorEmail(),
    watchGetUserDetail(),
    watchAutoLogin(),
    watchGetGenres(),
    watchGetLibraryComputerSlots(),
    watchCreateUserPreferenceSlot(),
    watchGetUserPreferencesSlots(),
    watchDeleteUserPreferenceSlot(),
    watchDeleteUserPreferencesSlotsSuccess(),
    watchGetLibraryStudentPreferenceSlots(),
    watchGetUserComputerReservationSlot(),
    watchCreateComputerReservationSlot(),
    watchGetLibraryComputers(),
    watchUpdateUserComputerReservationAttendance(),
    watchGetCareers(),
    watchGetLanguages(),
    watchGetSubjects(),
    watchGetTimezones(),
    watchLogin(),
    watchOnboardMentor(),
    watchOnboardStudent(),
    watchUpdateMentorProfile(),
    watchSubmitMentorSignUpForm(),
    watchSubmitStudentRegistration(),
    watchTaskStep(),
    watchGetOpportunity(),
    watchGetLibrary(),
    watchGetAnnouncements(),
    watchGetLibraryStudents(),
    watchGetLibraryMentors(),
    watchCreateLibraryComputers(),
    watchCreateAnnouncement(),
    watchUpdateAnnouncement(),
    watchDeleteAnnouncement(),
    watchChangeStudentStatus(),
    watchChangeMentorStatus(),
    watchLibraryUserPreferenceSlots(),
    watchLibraryComputerReservations(),
    watchLibraryTimeSlots(),
  ]);
}
